// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$fruc-fe-primary: mat.define-palette(mat.$indigo-palette);
$fruc-fe-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$fruc-fe-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$fruc-fe-theme: mat.define-light-theme((
  color: (
    primary: $fruc-fe-primary,
    accent: $fruc-fe-accent,
    warn: $fruc-fe-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($fruc-fe-theme);

/* Importing Bootstrap SCSS file. */

/* Importing Datepicker SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
@import 'ngx-toastr/toastr';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "tooltip";
@import "modal";


.toast-success {
  font-family: Roboto;
}


.title-toast {
  margin-bottom: 10px;
  font-weight: 600;
}


:root {
  --mainBaseColor: #ff6633;
  --mainBackgroundColor: #f7f8fa;
  --menuBackGroundColor: #ffffff;
  --headerColor: #26334d;
  --textColor: #2d3c59;
  --textFillColor: #ffffff;


}


/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, img, ins, kbd, q, s, samp,
small, sub, sup, tt, var, center,
dl, dt, dd,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  //word-break: break-word;
}

/*html {
  overflow: hidden;
}*/


a {
  text-decoration: none;
  color: inherit;
}

a, button {
  cursor: pointer;
}

s {
  text-decoration: line-through;
}

span {
  word-break: break-word;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
  padding: 0;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  font-family: Roboto;
  box-sizing: border-box;
  text-decoration: none;
  margin: 0;
}

u {
  text-decoration: underline;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.btn-custom {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
  border-radius: 30px;
  padding: 0 25px;
  font-weight: 800;
  font-size: 12px;
  letter-spacing: normal;
  line-height: 20px;
  text-align: center;
  height: 50px;

  .left {
    margin-right: 8px;
  }

  .right {
    margin-left: 8px;
  }

}

.btn-default {
  background: #FFFFFF;
  border: 2px solid var(--mainBaseColor);
  color: var(--mainBaseColor);

  .svg-wrap {
    display: flex;

    svg {
      path {
        fill: var(--mainBaseColor);
      }
    }

  }
}

.btn-active {
  color: var(--textFillColor);
  background: var(--mainBaseColor);
  border: 2px solid var(--mainBaseColor);

  .svg-wrap {
    display: flex;

    svg {
      path {
        fill: var(--textFillColor);
      }

      g {
        path {
          fill: var(--textFillColor);
        }
      }
    }

  }

}

.btn-delete {
  background: transparent;
  border: 2px solid #E62E2E;
  color: #E62E2E;

  .svg-wrap {
    display: flex;

    svg {
      path {
        fill: #E62E2E;
      }
    }

  }
}

.btn-xl {
  padding: 13px 25px;
  font-size: 14px;
  line-height: 30px;
  height: 60px;
}

.form {
  &-wrap {
    position: relative;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-label {
    color: var(--headerColor);
    margin-bottom: 12px;
    display: block;
    font-size: 16px;
    font-weight: 500;

    &-help {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__block {
        position: relative;

        svg {
          g {
            path {
              fill: var(--mainBaseColor);
            }
          }
        }

        &:hover {
          .form-label-help__text {
            display: block;
          }
        }
      }

      &__svg {
        width: 24px;
        height: 24px;
      }

      &__text {
        display: none;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        color: var(--textColor);
        border-radius: 15px;
        background: #FFF;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
        padding: 20px;
        width: 237px;
        position: absolute;
        top: 34px;
        left: 0;
      }
    }
  }

  &-input {
    outline: none;
    font-size: 12px;
    line-height: 20px;
    color: black;
    display: flex;
    align-items: center;
    width: 100%;
    background: #FFFFFF;
    border: 2px solid #F5F6F7;
    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
    border-radius: 30px;
    color: var(--textColor);
    padding: 13px 20px;
    //height: 42px;

    &::placeholder {
      //font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      color: #7D8FB3;
    }
  }

  &-textarea {
    height: 100px;
    padding: 15px 22px;
    resize: none;
  }

  &-file {
    &__wrapper {
      cursor: pointer;
      pointer-events: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border-radius: 30px;
      border: 1px dashed #7D8FB3;
      width: 100%;
      height: 50px;
    }

    &__input {
      display: none;
    }

    &__label {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #7D8FB3;
      font-size: 12px;
      font-weight: 900;
      line-height: 20px;
    }

    &__svg {
      width: 14px;
      height: 14px;
      margin-right: 8px;
    }
  }

  &-date {
    position: relative;

    &__input {
      &_svg {
        position: absolute;
        right: 22px;
        top: 12px;
        color: red;
        cursor: pointer;

        g {
          path {
            fill: var(--mainBaseColor)
          }
        }
      }
    }
  }

  &-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &-btn {
    height: 40px;
    padding: 10px 20px;
    color: var(--textFillColor);

    &_close {
      color: #E62E2E;
      border: 2px solid #E62E2E;
      background: transparent;
      box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
    }

    &_ml-20 {
      margin-left: 20px;
    }
  }

}


.page-title {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 35px;
  //color: var(--headerColor);
  margin-bottom: 40px;
}

.go-back {
  cursor: pointer;
  display: flex;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: var(--textColor);

  svg {
    margin-right: 15px;

    path {
      fill: var(--textColor);
    }
  }

  &:hover {
    color: var(--textColor);
  }
}

.input-error {
  color: red;
}

input.ng-touched.ng-invalid, textarea.ng-touched.ng-invalid {
  border: 1px solid red;
}

.f-title {
  color: var(--headerColor)

}

.f-text {
  color: var(--textColor)

}

//Стилизия меню


//select


.ng-value-container {
  position: relative;
  min-height: 40px;
  padding-top: 0px !important;
}

.ng-value {
  margin-right: 5px !important;
  //background: #FF6633;
  color: var(--textColor);
  border-radius: 15px;
  padding: 8px;
}

.ng-select {
  min-height: 50px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background: var(--mainBaseColor);
  color: white;
  border-radius: 15px;
  padding: 5px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: none;
}

.ng-select > .ng-select-container, .ng-select.ng-select-opened > .ng-select-container {
  border: 2px solid #F5F6F7;
  box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
  border-radius: 30px;
  outline: none;
  padding: 5px;

}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  white-space: break-spaces !important;
}

.ng-select.ng-select-single .ng-select-container {
  height: 100% !important;
}


.ng-select-container {
  border: 2px solid #F5F6F7 !important;
  box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03) !important;
  font-size: 12px;
}


.ng-placeholder {
  top: 13px !important;
}

.ng-value-icon:hover {
  background: var(--mainBaseColor) !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: var(--mainBaseColor);
  color: var(--textFillColor);
}

.ng-option {
  border-bottom: 2px solid #F5F6F7;
  color: var(--textColor);
  font-size: 12px;

  &:last-child {
    border: none;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  color: var(--textColor);
  background-color: none;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  color: var(--textColor);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 15px 20px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  background-color: var(--white-white, #FFF);
  color: var(--headerColor);
  font-size: 14px;
  padding: 8px 8px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
  background-color: var(--mainBaseColor);
  color: var(--textFillColor);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected {
  // background-color: var(--mainBaseColor);
  // color: var(--textFillColor);
}

.ng-dropdown-panel-items .select-group-checkbox {
  display: flex;
  align-items: center;
}

.ng-dropdown-panel-items .select-group-checkbox .container-check {
  padding-left: 0px;
  padding-right: 12px;
  margin-bottom: 0px;
}

/*.ng-dropdown-panel.ng-select-bottom {
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;

}

.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child{
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
}*/

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 13px
}

.ng-select.ng-touched.ng-invalid {
  .ng-select-container {
    border: 1px solid red !important;
  }

}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: var(--textColor) transparent transparent;

}

.ng-select .ng-arrow-wrapper:hover .ng-arrow {
  border-top-color: var(--textColor);
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  border-color: transparent transparent var(--textColor);
}


.cancel-picker {
  background: transparent;
  border: 1px solid #301258;
  box-sizing: border-box;
  border-radius: 8px;
  margin-right: 20px;
  outline: none;
  padding: 5px;
}

.cancel-picker {
  cursor: pointer;
  background: #FFFFFF;
  border: 2px solid #F5F6F7;
  box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
  border-radius: 30px;
  padding: 15px 15px;
  font-weight: 900;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  color: #6B7A99;

  &:hover {
    //background: #F3F3F4;
    //border: 2px solid #F3F3F3;
  }
}

.ok-picker-btn {
  font-weight: 900;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  color: var(--textFillColor);
  padding: 15px 15px;
  background: var(--mainBaseColor);
  border: none;
  box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
  border-radius: 30px;
  cursor: pointer;

  &:hover {
    //background: #A666D6;
  }
}


textarea {
  resize: none;

  &:focus-visible {
    outline: none;
  }
}


.page-wrap {
  padding: 40px;
  padding-left: 60px;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  @media (max-width: 600px) {
    padding: 20px;
    padding-bottom: 40px;
  }
}

.router-block {
  background: var(--mainBackgroundColor);
  border-radius: 0px 15px 15px 0px;
  width: 100%;
  flex: 1 1 auto;
  box-sizing: border-box;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 1024px) {
    margin-left: 0px;
    max-height: none;
    overflow: unset;

  }
}

//menu
.menu-list {
  margin-top: 45px;

  .item-wrap {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 10px 30px rgba(38, 51, 77, 0.03);
    border-radius: 30px;
    transition: max-height 0.3s ease-in-out;
    margin-bottom: 20px;
    background: var(--menuBackGroundColor);

    .item {
      transition: background-color 0.3s ease-in-out;
      cursor: pointer;
      width: 100%;
      display: flex;
      align-items: center;
      background-color: inherit;
      padding: 15px 27px;
      border-radius: 30px;

      .count {
        font-size: 10px;
        line-height: 20px;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: var(--textFillColor);
        background-color: var(--mainBaseColor);
        margin-left: 17px;
      }


      &:last-child {
        margin-bottom: 0px;
      }

      p {
        font-weight: 400;
        font-size: 13px;
        line-height: 25px;
        color: var(--textColor);
      }

      &--active {
        padding-bottom: 0px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;

        .icon {
          svg path {
            fill: var(--mainBaseColor);
          }
        }

        p {
          color: var(--mainBaseColor);
        }

        .arrow {
          transform: rotate(180deg);
        }

      }

      .icon {
        display: flex;
        margin-right: 20px;

        svg path {
          fill: var(--mainBaseColor);

        }

      }

      .arrow {
        margin-left: auto;

        svg path {
          fill: var(--textColor);
        }
      }

    }

    &--active--full {
      background: var(--mainBaseColor);

      .item {

        background: var(--mainBaseColor);

        .icon {
          svg path {
            fill: var(--textFillColor);
          }
        }

        p {
          color: var(--textFillColor);
        }

      }

    }


    .sub-item {
      display: flex;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-in-out;
      flex-direction: column;
      padding-right: 25px;
      padding-left: 25px;
      width: 100%;

      a {
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-style: normal;
        font-size: 13px;
        line-height: 25px;
        box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.05);
        border-radius: 30px;
        padding: 10px 20px;
        color: var(--textColor);
        margin-bottom: 15px;

        &:first-child {
          margin-top: 20px;
        }

        .count {
          font-size: 10px;
          line-height: 20px;
          display: flex;
          flex-shrink: 0;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          color: var(--textFillColor);
          background-color: var(--mainBaseColor);
          margin-left: 17px;
        }
      }

      &--active {
        max-height: 380px;

      }

      .active-submenu {
        background-color: var(--mainBaseColor);
        color: var(--textFillColor);
        /*.count{
            color: var(--mainBaseColor);
            background-color: var(--textFillColor);

        }*/

      }

    }
  }

}

.modal {
  &-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
  }

  &-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
  }

  &-hint {
    margin-top: 10px;

    span {
      font-weight: 600;
    }

    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    font-style: italic;
    color: var(--textColor);
  }
}


.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
  width: 100%;

  h1 {
    margin-bottom: 0px;
    word-break: break-word;
  }

  @media (max-width: 500px) {
    margin-bottom: 40px;
    flex-direction: column;
    .btn-active {
      margin-top: 20px;
    }

  }
}


//table
.table-responsive {
  display: block;
  width: 100%;
  //height: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  border-radius: 15px;
  max-height: 600px;

  table {
    width: 100%;
    background-color: white;
    padding: 20px;

    th, td {
      font-family: Roboto;
      padding: 20px;
      text-align: left;
      font-size: 12px;
      line-height: 20px;
    }

    th {
      color: var(--headerColor);
      font-weight: 600;
      position: sticky;
      top: 0px;
      background: white;
      z-index: 999;
      border-bottom: 2px solid #F5F6F7;


    }

    td {
      color: var(--textColor);
      border-bottom: 2px solid #F5F6F7;
      box-shadow: inset 0px 10px 8px -10px rgba(38, 51, 77, 0.05),


    }

    .td--word-break {
      word-break: break-word !important;
    }


  }
}

.table-responsive-wrap {
  flex-grow: 1;
}

th:first-of-type {
  border-top-left-radius: 10px;
}

th:last-of-type {
  border-top-right-radius: 10px;
}

tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 10px;
}

tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 10px;
}

//dropdown
.action-list {

  background: #FFFFFF;
  box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.12);
  border-radius: 15px;
  width: 200px;
  padding-bottom: 0px;
  padding-top: 0px;
  margin-top: -16px;
  margin-right: 31px;

  li {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: var(--textColor);
    border-bottom: 2px solid #F5F6F7;
    padding: 15px;
    cursor: pointer;

    &:first-child {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }

    &:last-child {
      border-bottom: none;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }

    &:hover {
      background-color: var(--mainBaseColor);
      color: var(--textFillColor);
    }
  }
}

.menu-btn {
  cursor: pointer;
  width: 30px;
  height: 30px;

  .svg-wrap {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      rect {
        fill: none
      }

      path {
        fill: var(--textColor);
      }
    }
  }
}

.open {
  .menu-btn {
    .svg-wrap {
      svg {
        rect {
          fill: var(--mainBaseColor)
        }

        path {
          fill: var(--textFillColor);
        }
      }
    }
  }
}


//checkbox
.checkbox-wrap {
  position: relative;
}

.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-checkbox + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.custom-checkbox + label::after {
  content: '';
  display: inline-block;
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid var(--mainBaseColor);
  border-radius: 0.25em;
  margin-left: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox:checked + label::after {
  border-color: var(--mainBaseColor);
  background-color: var(--mainBaseColor);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}


//radio
.custom-radio {
  position: absolute;
  z-index: -1;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

/* для элемента label связанного с .custom-radio */
.custom-radio + label {
  display: flex;
  align-items: center;
  user-select: none;
  border: 1px solid var(--mainBaseColor);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  position: relative;
}

/* создание в label псевдоэлемента  before со следующими стилями */
.custom-radio + label::before {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


/* стили для радиокнопки, находящейся в состоянии checked */
.custom-radio:checked + label::before {
  //border-color: var(--mainBaseColor);
  background-color: var(--mainBaseColor);
}


//Scrollbar
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}


::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--mainBaseColor);
  border-radius: 10px;
}


::-webkit-scrollbar-thumb:hover {
  background: var(--mainBaseColor);
}

*::-webkit-scrollbar-corner {
  background: var(--mainBackgroundColor);
}

th.empty {
  width: 60px;
}

.confirm-content {
  display: flex;
  flex-direction: column;

  .text {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: var(--headerColor);
  }

  .value {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: var(--textColor);
  }
}


// Общее
//checkbox
.container-check {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  p {
    color: black;
    font-size: 16px;
    line-height: 20px;

    a {
      text-decoration: underline;

    }
  }

}

/* Hide the browser's default checkbox */
.container-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  //background-color: #eee;
  border: 1px solid var(--mainBaseColor);
}

/* On mouse-over, add a grey background color */
.container-check:hover input ~ .checkmark {
  //background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-check input:checked ~ .checkmark {
  background-color: var(--mainBaseColor);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-check input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-check .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid var(--textFillColor);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);

}

.c--_primary______true-az {
  color: var(--textColor) !important;
}

.y-bg-l, .track-k {
  background-color: var(--mainBaseColor) !important;
  color: var(--mainBaseColor) !important;
}


.thumb-o {
  background-color: var(--mainBaseColor) !important;
}

.thumb-o:before {
  content: none !important;
}


//ckeditor
.ck-dropdown__panel.ck-dropdown__panel_sw {
  max-width: 100px !important;
}

.ck.ck-list__item .ck-button.ck-on:hover:not(.ck-disabled) {
  //background: orange!important;
}

/* You can add global styles to this file, and also import other style files */

.ck-fontsize-option {
  .ck-button__label {
    font-size: 10px !important;
    line-height: 10px !important;
  }
}


.ck-content {
  span, p, li {
    line-height: 120%;
  }

  ul, ol {
    padding-left: 2rem;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: auto;
  }

  p {
    color: var(--textColor);
  }

  h1, h2, h3, h4, h5, h6 {
    color: var(--headerColor);
  }

  a {
    //text-decoration: underline;
    color: #06c !important;

    * {
      color: #06c !important;
    }
  }
}

.ck-editor__editable {
  min-height: 300px !important;
  max-height: 80vh !important;
}


.ck-dropdown__panel.ck-dropdown__panel_sw {
  max-width: 100px !important;
}

.ck-font-size-dropdown {
  .ck-dropdown__panel {
    .ck-list {
      .ck-item {
        //height: 2px!important;
      }
    }

    max-height: 200px !important;
    overflow: auto;

  }

  .ck-dropdown__panel::-webkit-scrollbar {
    display: none;
  }

  max-height: 200px !important;
  //overflow: auto;

}

.ck-dropdown__panel::-webkit-scrollbar {
  display: none;
}


.ck-reset_all :not(.ck-reset_all-excluded *) {
  //color: #1a9aef!important;
}


.inner-text {
  ul, ol {
    padding-left: 2rem;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: auto;
  }

  img {
    max-width: 100%;
  }

  .table {
    margin: 0px auto;

    table {
      width: 100%;

      td {
        padding: 0.4em;
        min-width: 32px;
      }
    }


  }

  .table {
    display: table;
  }

  table {
    border-collapse: collapse;
  }

  table, td {
    border: 1px solid black;
    padding: 5px;
  }

  p, span, li {
    line-height: 120%;
  }

  .image-style-align-center {
    margin: 0 auto;
  }

  .image-style-block-align-right {
    margin-left: auto;
  }

}


.hint-block {
  position: absolute;
  font-size: 12px;
  color: var(--textFillColor);
  background: var(--mainBaseColor);
  width: 125px;
  text-align: center;
  line-height: 1.5;
  padding: 5px;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  top: 50px;
  z-index: 9999;
}

.open-wrap {
  position: relative;
}

.open:hover {
  .hint-block {
    display: flex;
  }

  .hint-block:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent var(--mainBaseColor) transparent;
    top: -20px;
    z-index: 9999;
  }
}

.open-wrap:hover {
  .hint-block {
    display: flex;
  }

  .hint-block:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent var(--mainBaseColor) transparent;
    top: -20px;
    z-index: 9999;
  }
}

figure.table {
  float: none !important;
  max-width: 100%;
}

.object-fit {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transform: translate3d(0, 0, 1px);
}


.disable-copy {
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;

}

.search {
  position: relative;

  &__input {
    padding-left: 41px;
  }

  &__svg {
    width: 15px;
    height: 15px;
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
  }

  &-btn {
    color: var(--mainBaseColor);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    position: absolute;
    right: 12px;
    top: 10px;

    &_close {
      color: #E62E2E;
      right: 16px;
      top: 14.5px;
    }

    &__svg {
      &_right {
        margin-left: 3px;

        path {
          fill: var(--mainBaseColor)
        }
      }

      &_left {
        margin-right: 10px;
      }
    }
  }
}

.checkbox {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: var(--mainBaseColor);

  &__box {
    width: 20px;
    height: 20px;
    border: 3px solid #2D3C59;
    border-radius: 3px;
    margin-right: 10px;
    position: relative;
    color: var(--textColor);
  }

  &__svg {
    width: 25px;
    height: 25px;
    display: none;
    object-fit: cover;
    position: absolute;
    top: -2.5px;
    left: -2.5px;
  }

  &__input {
    position: absolute;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:checked + .checkbox__box {
      border: none;
      background-color: var(--textFillColor);

      .checkbox__svg {
        display: block;

        g {
          path {
            fill: var(--mainBaseColor);
          }

        }

      }
    }
  }
}

.ng-autocomplete-custom {
  width: 100%;

  .autocomplete-container {
    box-shadow: none !important;

  }

  .autocomplete-container.active {
    .input-container input {
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }

  }


  .input-container input {
    outline: none;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    width: 100%;
    background: #FFFFFF;
    border: 2px solid #F5F6F7;
    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
    border-radius: 30px;
    color: var(--textColor);
    padding: 13px 25px;

    &::placeholder {
      font-size: 12px;
      line-height: 20px;
      color: #7D8FB3;
    }
  }

  .auto-item {
    color: var(--textColor) !important;

    &:hover {
      background-color: var(--mainBaseColor);
      color: var(--textFillColor) !important;
    }

  }

  .autocomplete-container .suggestions-container {
    position: absolute;
    width: 100%;
    background: white;
    height: auto;
    box-shadow: 0 1px 1px #00000040;
    box-sizing: border-box;
  }
}


html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.company-students-item {
  &-card {
    display: flex;
    //align-items: stretch;
    flex-wrap: wrap;
    padding-bottom: 30px;
    margin: -8px;

    &__card {
      margin: 8px;
      width: 268px;
      min-height: 82px;
      display: flex;
      flex-direction: column;
      padding: 10px 15px;
      border-radius: 10px;
      border: 1px solid #DFDFDF;
      background: #FFF;
      color: var(--textColor);
    }

    &__subtitle {
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 20px;
    }

    &__title {
      font-size: 30px;
      font-weight: 700;
      line-height: 120%;
      color: var(--headerColor);

    }
  }
}


.tip {
  display: flex;
  align-items: center;

  span {
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;

  }

}

.ck.ck-editor__editable_inline {
  padding: 20px !important;
}

.ck.ck-editor__editable_inline > :first-child {
  margin-top: 0 !important;
}

//

.input-wrap-checkbox {
  width: 24px;
  height: 24px;
  margin-bottom: 0px !important;
}

//
.file-wrapper--button {
  position: relative;
  display: flex;
  flex-direction: column;

  input[type=file] {
    opacity: 0;
    visibility: hidden;
    position: absolute;
  }

  label.label-file {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #7D8FB3;
    border-radius: 30px;
    padding: 10px;
    font-size: 12px;
    line-height: 20px;
    color: #7D8FB3;
    max-width: 200px;
    font-weight: 700;

    img {
      margin-right: 10px;
    }
  }
}

.file-preview {
  display: flex;
  align-items: center;

  .download-button {
  }

  a {
    display: flex;
    width: 25px;
    height: 25px;
    margin-left: 15px;
    cursor: pointer;
  }
}


.tooltip {
  --bs-emphasis-color: var(--mainBaseColor);
}

.bs-tooltip-bottom {
  top: 6px !important;
}

.bs-tooltip-top {
  top: -6px !important;
}

.tooltip-inner {
  color: var(--textFillColor);
}

.fr-tag {
  color: var(--textColor);
  font-size: 14px;
  font-weight: 400px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
  gap: 10px;
  background: #FFFFFF;
  border: 1px solid #E3E3E3;
  border-radius: 50px;
  cursor: pointer;
}

.info-block {
  padding: 20px;
  background: white;
  border-radius: 16px;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 30px;
  color: var(--textColor);

  span {
    font-weight: 600;
  }
}

//progress-bar
.progress{
  --bs-progress-bar-bg: var(--mainBaseColor);

}
