.modal {
  &-header{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 25px;
  }
  &-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
  }

  &-form{
    display: flex;
    flex-direction: column;
    gap:  30px 0;

  }

  &-form-item{
    display: flex;
    flex-direction: column;
    .modal-label{
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: var(--headerColor);
      margin-bottom: 12px;

    }
  }

  &-buttons{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 30px;
    @media (max-width:500px) {
      justify-content: center;
      flex-direction: column;
      button{
        width: 100%;
      }
      &--preloader{
        display: flex;
        width: 100%;
      }


    }
  }
}

.modal-wrap{
  display: flex;
  flex-direction: column;

}



.modal_buttons{
  display: flex;
  justify-content: space-between;
}
